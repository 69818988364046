<template>
  <v-container fluid class="pa-0">
    <v-slide-group
      v-model="currentPill"
      :show-arrows="$vuetify.breakpoint.smAndUp"
      center-active
    >
      <v-slide-item v-for="(category, index) in categories" :key="index">
        <v-btn
          @click="scrollTo(category.domScrollTarget)"
          color="secondary"
          class="mx-2 px-3 accent--text font-weight-regular text-body-2 py-0"
          :class="
            category.id === $store.state.menu.currentVisibleSectionId
              ? 'no-active white--text'
              : ''
          "
          depressed
          height="31"
          rounded
        >
          <span class="text-none">
            {{ normalizePillText(category.name) }}
          </span>
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-container>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    scrollOptions: {
      duration: 1000,
      offset: 0,
      easing: "easeInOutCubic",
    },
  }),
  computed: {
    currentPill() {
      const currentIndex = this.$store.getters["menu/categories"].findIndex(
        (category) => {
          return category.id === this.$store.state.menu.currentVisibleSectionId;
        }
      );

      return currentIndex;
    },
  },
  methods: {
    scrollTo(target) {
      this.$vuetify.goTo(`#${target}`, this.scrollOptions);
    },
    normalizePillText(string) {
      string = string.toLowerCase();
      const arr = string.split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const string2 = arr.join(" ");
      return string2;
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .no-active
  background-color:  var(--v-primary-base) !important
  opacity: 1 !important
  > span
    color: white
</style>