<template>
  <v-app-bar color="white" elevation="0">
    <v-row class="px-2 pt-4 pb-3" align="center">
      <v-col cols="8" class="py-0 px-1">
        <v-row justify="start" align="center">
          <!-- <base-img
            v-if="logoUrl"
            class="pr-3"
            :src="logoUrl"
            contain
            max-width="4rem"
            max-height="2.5rem"
          /> -->
          <v-col>
            <h1 class="text-h5 black--text">
              {{ commerceName }}
            </h1>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row justify="end">
          <v-btn color="secondary" fab elevation="0" small @click="scrollToTop">
            <v-icon color="accent">mdi-chevron-up</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    commerceName: {
      type: String,
      default: "",
    },
    logoUrl: {
      type: String,
      default: "",
    },
  },
  methods: {
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style>

</style>