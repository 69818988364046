<template>
  <v-row
    v-show="!isCollapsed"
    no-gutters
    class="pt-4 fill-height"
    justify="end"
  >
    <v-col cols="6">
      <v-row justify="center" align="center" class="fill-height pt-3">
        <div>
          <v-row justify="center">
            <base-img
              v-if="logoUrl"
              contain
              :src="logoUrl"
              max-width="10rem"
              max-height="3.5rem"
            />
            <v-col cols="12" class="px-0 py-3">
              <v-toolbar-title class="text-center text-h5 font-weight-thin">
                {{ commerceName }}
              </v-toolbar-title>
            </v-col>
            <!-- <v-icon size="28"> $vuetify.icons.chefHat </v-icon> -->
          </v-row>
        </div>
      </v-row>
    </v-col>
    <v-col cols="3">
      <v-row justify="end">
        <v-btn v-if="instagramUrl" target="_blank" :href="instagramUrl" icon>
          <v-icon>mdi-instagram</v-icon>
        </v-btn>

        <v-btn v-if="facebookUrl" target="_blank" :href="facebookUrl" icon>
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    commerceName: {
      type: String,
      default: "",
    },
    logoUrl: {
      type: String,
      default: "",
    },
    instagramUrl: {
      type: String,
      default: "",
    },
    facebookUrl: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
