<template>
  <v-app-bar
    v-if="!backgroundMenuUrl"
    app
    dark
    hide-on-scroll
    v-scroll="barScroll"
    :height="collapsedBar ? 0 : 180"
    :class="collapsedBar ? 'force-complete-shrink' : ''"
    :extension-height="collapsedBar ? 130 : 60"
    :src="
      $store.state.menu.profile.menuLogoUrl
        ? $store.state.menu.profile.menuLogoUrl
        : require('@/assets/img/brand/banner-background.jpg')
    "
  >
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top, rgba(0,0,0,.6), rgba(0,0,0,.8)"
      ></v-img>
    </template>

    <template v-slot:extension class="pa-0">
      <v-container class="pa-0">
        <v-row>
          <v-col v-if="collapsedBar" cols="12" class="pa-0">
            <collapsed-app-bar
              :logo-url="$store.state.menu.profile.shopLogoUrl"
              :commerce-name="$store.state.menu.profile.shopName"
            />
          </v-col>
          <v-col cols="12" class="px-1" v-if="!hasChildShops">
            <category-pills :categories="categories" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <expanded-app-bar
      :logo-url="$store.state.menu.profile.shopLogoUrl"
      :facebook-url="$store.state.menu.profile.facebookUrl"
      :instagram-url="$store.state.menu.profile.instagramUrl"
      :commerce-name="$store.state.menu.profile.shopName"
      :shop-description="$store.state.menu.profile.shopDescription"
      :is-collapsed="collapsedBar"
    />
  </v-app-bar>
</template>

<script>
import CollapsedAppBar from "@/components/CollapsedAppBar";
import ExpandedAppBar from "@/components/ExpandedAppBar";
import CategoryPills from "@/components/CategoryPills";
import shopUtils from "@/mixins/shopUtils";

export default {
  mixins: [shopUtils],
  data: () => {
    return {
      collapsedBar: false,
    };
  },

  components: {
    ExpandedAppBar,
    CollapsedAppBar,
    CategoryPills,
  },

  computed: {
    categories() {
      return this.$store.getters["menu/categories"];
    },

    backgroundMenuUrl() {
      return this.$store.getters["menu/backgroundMenuUrl"];
    },
  },

  methods: {
    barScroll(e) {
      const scrollTop = e.target.scrollingElement.scrollTop;
      if (scrollTop < 1) {
        this.collapsedBar = false;
      } else {
        this.collapsedBar = true;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
$tab-font-size: map-deep-get($headings, 'body-2', 'size') !important
.force-complete-shrink
  transform: translateY(-56px) !important
::v-deep .v-toolbar__extension
  background-color: white
  box-shadow: 0 0.6rem 1.2rem rgba(224, 224, 224, 0.8)
</style>
